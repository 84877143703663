<template>
  <div
    class="auth-layout-wrap"
  >
    <div class="auth-content">
      <div class="card o-hidden custom-form">
        <div class="row">
          <div class="col-md-12">
            <div class="p-80">
              <div class="text-center">
                <h1 class="mb-3 text-30 font-weight-bold">Reset Password</h1>
                <p class="text-15">Please enter your password.</p>
              </div>
              <form action="" class="mt-30">
                <b-form-group label="New password" class="text-12">
                  <b-form-input
                    type="password"
                    v-model="newpass"
                    required
                    size="lg"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Confirm password" class="text-12">
                  <b-form-input
                    type="password"
                    v-model="confirmpass"
                    required
                    size="lg"
                  ></b-form-input>
                </b-form-group>
                <div class="d-flex justify-content-between">
                  <b-button class="btn btn-block mt-30" size="lg">
                    Cancel
                  </b-button>
                  <b-button variant="primary" class="btn btn-block mt-30 ml-30" size="lg" @click="formSubmit">
                    Submit
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Reset Password"
  },
  data() {
    return {
      bgImage: require("@/assets/images/photo-wide-4.jpg"),
      logo: require("@/assets/images/logo.png"),
      formImage: require("@/assets/images/photo-long-3.jpg"),
      newpass: "",
      confirmpass: ""
    };
  },
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13;
    },
  },
  methods: {
    formSubmit() {
      this.$router.push("/app/sessions/startup");
    },
  },
};
</script>

<style>
.spinner.sm {
  height: 2em;
  width: 2em;
}
.custom-flex {
  display: flex;
  justify-content: space-between;
}
.auth-layout-wrap:after {
    content: '';
    background-image: url("~@/assets/images/bg/startup-bg.jpeg");
    width: 100%;
    height: 100%;
    position:absolute;
    top:0;
    left:0;
    background-size: cover;
}
</style>
